// ------ Data Source Queries -------//

export const constraintFailuresCount = /* GraphQL */ {
  variables: [{ name: "dataSourceId", type: "Int!" }],
  fragment: /* GraphQL */ `
        constraintFailuresCount {
          failureCount
          percentChange
        }
    `,
};

// Fragment object with variable data
export const failureOpportunities = /* GraphQL */ {
  variables: [{ name: "dataSourceId", type: "Int!" }],
  fragment: /* GraphQL */ `
        # Opportunities for Failure
        failureOpportunities {
          # Count of failure opportunities
          opportunitiesCount
          # Percentage change in failure opportunities
          percentChange
        }
    `,
};

export const failuresHistory = /* GraphQL */ {
  variables: [{ name: "dataSourceId", type: "Int!" }],
  fragment: /* GraphQL */ `
      # Failure History for Source
      failuresHistory {
        history {
          # Date of the failure history
          date
          # Count of failures in the history
          failures
          # Count of opportunities in the history
          opportunities
        }
        # Source name of the failures history
        sourceName
      }
    `,
};

export const rowCount = /* GraphQL */ {
  variables: [{ name: "dataSourceId", type: "Int!" }],
  fragment: /* GraphQL */ `
      # Number of rows in the Source
      rowCount {
         # Count of rows
         rowCount
        # Percentage change in row count
        percentChange
      }
    `,
};

export const ruleFailures = /* GraphQL */ {
  variables: [{ name: "dataSourceId", type: "Int!" }],
  fragment: /* GraphQL */ `
      # Policy Failures
      ruleFailures {
        failures {
          # Identifier of the failed rule instance
          failedRuleInstanceId
          # Version identifier of the failed rule instance
          failedRuleInstanceVersionId
          # Identifier of the failed rule standard
          failedRuleStandardId
          # Name of the failed rule instance
          failedRuleInstanceName
          # Name of the failed rule standard
          failedRuleStandardName
          # Version identifier of the failed rule standard
          failedRuleStandardVersionId
          # Count of successes
          successCount
          # Count of failures
          failureCount
          # Percentage of failures
          failurePercentage
          # Count of incorrect value failures
          incorrectValueFailureCount
          # Percentage of incorrect value failures
          incorrectValueFailurePercentage
          # Count of no matching row failures
          noMatchingRowFailureCount
          # Percentage of no matching row failures
          noMatchingRowFailurePercentage
          # Count of null failures
          nullFailureCount
          # Percentage of null value failures
          nullValueFailurePercentage
          # Count of errors
          errorCount
          # Percentage of errors
          errorPercentage
          # Priority of the failed business rule instance
          failedBusinessRuleInstancePriority
        }
        # Source name of the rule failures
        sourceName
      }
    `,
};

// Fragment object with variable data
export const totalFailures = /* GraphQL */ {
  variables: [{ name: "dataSourceId", type: "Int!" }],
  fragment: /* GraphQL */ `
        totalFailures {
          failuresCount
          percentChange
        }
    `,
};

// Get Available Data Sources
export const availableDataSourcesPermissionCheck = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $order: [DataSourceSortInput!]
    $where: DataSourceFilterInput
    $customSort: [CustomSortInputModelInput!]
    $permissionCode: Int!
  ) {
    availableDataSourcesPermissionCheck(
      first: $first
      after: $after
      order: $order
      where: $where
      customSort: $customSort
      permissionCode: $permissionCode
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          enabled
          priorityLevel
          activeMonitoring
          nextScheduledReport
          reportStatus
          tagInstances {
            id
            remoteObjectId
            tag {
              name
              description
              id
            }
            tagId
            type
          }
          connection {
            id
            name
          }
          ingressInfo {
            id
          }
          ruleInstancesCount
          latestReport {
            timestamp
            refreshSummaryId
            refreshSummary {
              createdOn
              resultState
            }
            qualityScore {
              score
            }
          }
        }
      }
    }
  }
`;

// Get Available Data Sources
export const availableDataSources = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $tagFilter: CustomTagFilterInput!
    $order: [DataSourceSortInput!]
    $where: DataSourceFilterInput
    $customSort: [CustomSortInputModelInput!]
  ) {
    availableDataSources(
      first: $first
      after: $after
      tagFilter: $tagFilter
      order: $order
      where: $where
      customSort: $customSort
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          schema
          enabled
          priorityLevel
          activeMonitoring
          nextScheduledReport
          reportStatus
          tagInstances {
            id
            remoteObjectId
            tag {
              name
              description
              id
            }
            tagId
            type
          }
          columns {
            enabled
            id
            name
          }
          connection {
            id
            name
          }
          ingressInfo {
            id
          }
          egressInfo {
            id
          }
          ruleInstancesCount
          latestReport {
            timestamp
            refreshSummaryId
            refreshSummary {
              createdOn
              resultState
            }
            qualityScore {
              score
            }
          }
          ruleInstances() {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        edges {
          cursor
          node {
            standardId
            priority
            approvalState
            dataSourceId
            enabledState
            id
            latestVersion {
              id
              standardVersion {
                id
                standard {
                  id
                  enabled
                  latestVersion {
                    id
                  }
                }
              }
              standardVersionId
            }
            title
          }
        }
      }
        }
      }
    }
  }
`;

// Get Available Data Sources for the data source list page - doesn't load excess stuff like columns and egress/ingress for improved speed
export const availableDataSourcesBare = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $tagFilter: CustomTagFilterInput!
    $order: [DataSourceSortInput!]
    $where: DataSourceFilterInput
    $customSort: [CustomSortInputModelInput!]
  ) {
    availableDataSources(
      first: $first
      after: $after
      tagFilter: $tagFilter
      order: $order
      where: $where
      customSort: $customSort
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          schema
          enabled
          priorityLevel
          activeMonitoring
          nextScheduledReport
          reportStatus
          tagInstances {
            id
            remoteObjectId
            tag {
              name
              id
            }
            tagId
            type
          }
          connection {
            id
            name
          }
          latestReport {
            timestamp
            refreshSummaryId
            refreshSummary {
              createdOn
              resultState
            }
            qualityScore {
              score
            }
          }
          ruleInstancesCount
          primaryKeyConfigured
        }
      }
    }
  }
`;

//Get Sources By ID Columns
// Get Available Data Sources
export const dataSourcesByIdColumns = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $order: [DataSourceSortInput!]
    $where: DataSourceFilterInput
  ) {
    availableDataSources(
      first: $first
      after: $after
      order: $order
      where: $where
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          schema
          enabled
          columns {
            fullyQualifiedName
            dataSourceId
            dataType
            enabled
            id
            name
            flags
            ordinal
          }
          tagInstances {
            id
            remoteObjectId
            tag {
              name
              description
              id
            }
            tagId
            type
          }
        }
      }
    }
  }
`;

// Get Available Data Sources
export const archivedDataSources = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $order: [DataSourceSortInput!]
    $where: DataSourceFilterInput
    $customSort: [CustomSortInputModelInput!]
  ) {
    availableDataSources(
      first: $first
      after: $after
      order: $order
      where: $where
      customSort: $customSort
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          schema
          enabled
          priorityLevel
          connection {
            id
            name
          }
          ingressInfo {
            id
          }
          egressInfo {
            id
          }
          ruleInstancesCount
          latestReport {
            timestamp
            refreshSummaryId
            refreshSummary {
              createdOn
              resultState
            }
            qualityScore {
              score
            }
          }
        }
      }
    }
  }
`;

// Get Available Data Source Report History
export const reportHistory = /* GraphQL */ `
  query ($id: Int!, $first: Int, $after: String) {
    dataSource(id: $id) {
      name
      id
      reportHistoryCount
      reportHistory(first: $first, after: $after) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        edges {
          cursor
          node {
            createdOn
            id
            resultState
            dataSourceId
            batchName
            metadataMetrics {
              metricType
              metricValue
            }
          }
        }
      }
    }
  }
`;

// Get Available Data Source Report History
export const basicInfo = /* GraphQL */ `
  query ($id: Int!) {
    dataSource(id: $id) {
      name
      id
    }
  }
`;

// Get Available Data Source Report History Count
export const reportHistoryCount = /* GraphQL */ `
  query ($id: Int!) {
    dataSource(id: $id) {
      id
      name
      reportHistoryCount
    }
  }
`;

// Get Available Data Sources Count
export const availableDataSourcesCount = /* GraphQL */ `
  query {
    availableDataSourcesCount
  }
`;

//////////////////////////////
// ------ Failures -----    //
//////////////////////////////

// Get Failure Opportunities
export const getFailuresHistoryReportForDataSource = /* GraphQL */ `
  query ($dataSourceId: Int!) {
    getFailuresHistoryReportForDataSource {
      history {
        date
        failures
        opportunities
      }
      sourceName
    }
  }
`;

//////////////////////////////
// ---- Score           --- //
//////////////////////////////

export const qualityScore = /* GraphQL */ {
  variables: [{ name: "dataSourceId", type: "Int!" }],
  fragment: /* GraphQL */ `
      qualityScore {
        date
        score
        sourceName
      }
    `,
};

export const scoreHistory = /* GraphQL */ {
  fragment: /* GraphQL */ `
      scoreHistory {
        history {
          score
          time
        }
        sourceName
      }
    `,
};

// Get Score History
export const getScoreHistoryReportForDataSource = /* GraphQL */ `
  query ($dataSourceId: Int!) {
    getScoreHistoryReportForDataSource {
      history {
        score
        time
      }
      sourceName
    }
  }
`;

// Get Score
export const getQualityScoreReportForDataSource = /* GraphQL */ `
  query ($dataSourceId: Int!) {
    getQualityScoreReportForDataSource {
      date
      score
      sourceName
    }
  }
`;

const fragments_fragment = /* GraphQL */ `
    fragments {
      executionOrder
      id
      standardVersionId
      typeInformation{
        fragmentId
        fragmentValue
        typeHierarchyLevel
        typeValue
      }
    }
  `;

// Data Source Row Retrieval Request
export const dataSourceRowRetrievalRequest = /* GraphQL */ `
  query ($id: String!) {
    dataSourceRowRetrievalRequest(id: $id) {
      columnQueryValue
      dataSourceId
      exceptionInformation
      finishedOn
      id
      rowData
      startedOn
    }
  }
`;

// Get Data Source By Id
export const dataSourceName = /* GraphQL */ `
  query ($id: Int!) {
    dataSource(id: $id) {
      id
      name
    }
  }
`;

// Get Data Source By Id Status
export const customReportById = /* GraphQL */ `
  query ($reportId: Int!) {
    customReportById(reportId: $reportId) {
      id
      name
      exportReport
      columns {
        fullyQualifiedName
        dataSourceId
        dataType
        enabled
        id
        name
        flags
        ordinal
      }
      policyGroup
      primaryDataSource {
        id
      }
      instanceVersions {
        id
      }
      type
    }
  }
`;

// Get Data Source By Id Status
export const customReportDataSourcesByRuleIds = /* GraphQL */ `
  query ($dataSourceId: Int!, $instanceVersionIds: [Long!]!) {
    customReportDataSourcesByRuleIds(
      dataSourceId: $dataSourceId
      instanceVersionIds: $instanceVersionIds
    ) {
      id
      name
      enabled
      columns {
        fullyQualifiedName
        dataSourceId
        dataType
        enabled
        id
        name
        flags
        ordinal
      }
    }
  }
`;

// Get Data Source By Id Status
export const customReportDataSourcesByPolicyGroup = /* GraphQL */ `
  query ($dataSourceId: Int!, $policyGroup: PolicyGroups!) {
    customReportDataSourcesByPolicyGroup(
      dataSourceId: $dataSourceId
      policyGroup: $policyGroup
    ) {
      id
      name
      enabled
      columns {
        fullyQualifiedName
        dataSourceId
        dataType
        enabled
        id
        name
        flags
        ordinal
      }
    }
  }
`;

// Get Data Source By Id Status
export const dataSourceStatus = /* GraphQL */ `
  query ($id: Int!) {
    dataSource(id: $id) {
      # Unique identifier of the data source
      id
      # Name of the data source
      name
      # Status indicating whether the data source is enabled
      enabled
      # Report status of the data source
      reportStatus
      # Count of rule instances associated with the data source
      ruleInstancesCount
      latestReport {
        # Identifier for the refresh summary
        refreshSummaryId
        qualityScore {
          # this is your report score
          score
        }
      }
    }
  }
`;

// Get a Data Source's matching column
export const getMatchingColumns = /* GraphQL */ `
  query ($model: DataSourceColumnMatchingInputModelInput!) {
    matchingColumns(model: $model) {
      matchingColumns {
        key {
          fullyQualifiedName
          dataSourceId
          dataType
          enabled
          id
          name
          flags
          ordinal
        }
        value {
          key {
            fullyQualifiedName
            dataSourceId
            dataType
            enabled
            id
            name
            flags
            ordinal
          }
          value
        }
      }
    }
  }
`;

// Get Data Columns
export const dataSourceColumnsSimple = /* GraphQL */ `
  query ($id: Int!) {
    dataSource(id: $id) {
      id
      name
      columns {
        fullyQualifiedName
        dataSourceId
        dataType
        enabled
        id
        name
        flags
        ordinal
      }
    }
  }
`;

// Get Data Columns
export const dataSourceColumns = /* GraphQL */ `
  query ($id: Int!) {
    dataSource(id: $id) {
      id
      name
      tagInstances {
        id
        remoteObjectId
        tag {
          name
          description
          id
        }
        tagId
        type
      }
      primaryKeyConfigured
      description
      priorityLevel
      qualityImpact
      columns {
        fullyQualifiedName
        dataSourceId
        matchingColumn {
          dataSourceColumnId1
          dataSourceColumnId2
          dataSourceColumn2 {
            id
            fullyQualifiedName
            name
            dataSourceId
            dataSource {
              id
              name
              columns {
                dataSourceId
                fullyQualifiedName
                dataType
                enabled
                id
                name
                flags
                ordinal
              }
            }
          }
        }
        dataType
        enabled
        id
        name
        flags
        ordinal
        uniqueValueCollectionId
        valuesCollection {
          id
          name
          uniqueValues {
            id
            value {
              value
            }
          }
        }
        userOverrideDataType
      }
      ingressInfo {
        details
        detailsType
        archiveAction
        characterFileIngressDetails {
          containerName
          directoryPathToFile
          fileNameWithWildcard
          hasHeaderRow
          rowElement
          valueSeparator
          ignoreQuotes
          quoteCharacter
        }
        fileIngressDetails {
          containerName
          directoryPathToFile
          fileNameWithWildcard
          hasHeaderRow
          rowElement
        }
      }
      egressInfo {
        details
        enabled
        type
        targetConnectionId
      }
    }
  }
`;

export const dataSourceForCrosstable = /* GraphQL */ `
  query ($id: Int!) {
    dataSourceForCrosstable(id: $id) {
      primaryDataSource {
        id
        name
        enabled
        columns {
          dataSourceId
          dataType
          enabled
          id
          name
          fullyQualifiedName
          flags
          ordinal
        }
      }
      secondaryDataSources {
        id
        name
        columns {
          dataSourceId
          dataType
          enabled
          id
          name
          fullyQualifiedName
          flags
          ordinal
        }
      }
      matchingColumns {
        dataSourceColumn1 {
          id
          dataSourceId
          name
        }
        dataSourceColumn2 {
          id
          dataSourceId
          name
        }
      }
    }
  }
`;

// Get Data Source By Id
export const dataSourceStandardInstanceVersion = /* GraphQL */ `
  query($id: Int!, $instanceVersionId: Long!) {
      dataSource(id: $id) {
        id
        columns {
          dataSourceId
          dataSource {
            id
            name
          }
          matchingColumn {
            dataSourceColumnId1
            dataSourceColumnId2
            dataSourceColumn2 {
              id
              name
              dataSourceId
              dataSource {
                id
                name
                columns {
                  dataSourceId
                  dataSource {
                    name
                    id
                  }
                  dataType
                  enabled
                  id
                  name
                  fullyQualifiedName
                  flags
                  ordinal
                }
              }
            }
          }
          dataType
          enabled
          id
          name
          flags
          ordinal
        }
        ruleInstanceVersionById(instanceVersionId: $instanceVersionId) {
            id
            instanceId
            instance {
              isACrosstabRuleInstance
              isAReconciliationRuleInstance
              title
            }
            createdOn
            standardVersionId
            standardVersion {
              id
              ${fragments_fragment}
              standard {
                enabled
                id
                latestVersion {
                  id
                }
              }
            }
            mappings {
              businessRuleFragment {
                executionOrder
                id
                standardVersion {
                  id
                  standardId
                }
              }
              column {
                dataSourceId
                fullyQualifiedName
                dataType
                enabled
                id
                name
                flags
                ordinal
              }
              columnId
              businessRuleFragmentId
              instanceVersionId
              id
            }
            calculationMappings
            {
              order
              argument
              columnId
              businessRuleFragmentId
            }
        }
      }
  }
`;

// Get Data Permissions
export const dataSourcePermissions = /* GraphQL */ `
  query ($id: Int!) {
    dataSource(id: $id) {
      id
      permissions {
        accessingWorkGroupId
        permissionCode
      }
    }
  }
`;

// Get Data Columns
export const dataSourceDetails = /* GraphQL */ `
  query ($id: Int!) {
    dataSource(id: $id) {
      id
      scheduledJob {
        id
        name
        description
        enabled
        jobType
        recurrenceInHours
        maxExecutionTimeInHours
        disabledOn
        workingDayObservation
        timeZoneUtcOffset
      }
      description
      priorityLevel
      qualityImpact
      nextScheduledReport
      activeMonitoring
      etlParameterName
      connection {
        name
        id
      }
      tagInstances {
        id
        remoteObjectId
        tag {
          name
          description
          id
        }
        tagId
        type
      }
      etlPipeline {
        etlPipeline {
          etlPipelineName
          etlProviderInstanceId
        }
        dependentDataSourceId
        dependentEtlPipelineId
        dataQualityThreshold
      }
      ingressInfo {
        details
        detailsType
        archiveAction
        characterFileIngressDetails {
          containerName
          directoryPathToFile
          fileNameWithWildcard
          hasHeaderRow
          rowElement
          valueSeparator
          ignoreQuotes
          quoteCharacter
          directoryPathToFile
          createFullPath
        }
        fileIngressDetails {
          containerName
          directoryPathToFile
          fileNameWithWildcard
          hasHeaderRow
          rowElement
          directoryPathToFile
          createFullPath
        }
        id
        sourceId
        targetConnection {
          id
        }
      }
      egressInfo {
        id
        details
        enabled
        type
        targetConnectionId
      }
      transferEgress {
        details
        enabled
        detailsType
      }
      standardEgress {
        details
        enabled
        detailsType
      }
    }
  }
`;

// Get Data Source By Id
export const dataSourceAlerts = /* GraphQL */ `
  query ($id: Int!) {
    dataSource(id: $id) {
      id
      alerts {
        id
        enabled
        instances {
          id
          alertId
          notificationType
          enabled
          members {
            id
            alertInstanceId
            userId
            user {
              displayName
              emailAddress
              id
              profilePhoto
            }
            workGroupId
            group {
              displayName
              id
            }
          }
        }
        threshold
        boolFlag
        alertType
      }
    }
  }
`;

// Get Data Source By Id
export const dataSourceStandardsList = /* GraphQL */ `
  query (
    $id: Int!
    $first: Int
    $after: String
    $where: BusinessRuleInstanceFilterInput
    $order: [BusinessRuleInstanceSortInput!]
  ) {
    dataSource(id: $id) {
      id
      name
      ruleInstancesCount
      ruleInstances(
        first: $first
        after: $after
        where: $where
        order: $order
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        edges {
          cursor
          node {
            standardId
            priority
            approvalState
            dataSourceId
            enabledState
            id
            latestVersion {
              id
              standardVersion {
                id
                standard {
                  id
                  enabled
                  latestVersion {
                    id
                  }
                }
              }
              standardVersionId
            }
            title
          }
        }
      }
    }
  }
`;

// Get Data Source By Id
export const dataSourceStandardsListWithColumns = /* GraphQL */ `
  query (
    $id: Int!
    $first: Int
    $reportId: UUID!
    $after: String
    $where: BusinessRuleInstanceFilterInput
    $order: [BusinessRuleInstanceSortInput!]
  ) {
    dataSource(id: $id) {
      id
      name
      ruleInstancesCount
      reportById(reportId: $reportId) {
      # Profile Data for Columns
      columnProfiles {
        # Profile Results for Columns [array]
        profileResults {
          # Identifier of the column
          columnId
          # Value of the profile result
          value
          # Subtype of the value
          valueSubType
          # Type of the value
          valueType
        }
      }
        ${ruleFailures.fragment}
      }
      ruleInstances(
        first: $first
        after: $after
        where: $where
        order: $order
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        edges {
          cursor
          node {
            standardId
            standard {
              name
              description
            }
            priority
            approvalState
            dataSourceId
            enabledState
            id
            versions {
              id
              instanceId
              mappings {
                columnId
                businessRuleFragmentId
              }
              calculationMappings {
                columnId
                businessRuleFragmentId
              }
            }
            latestVersion {
              id
              standardVersion {
                id
                standard {
                  id
                  enabled
                  latestVersion {
                    id
                  }
                }
              }
              standardVersionId
            }
            title
          }
        }
      }
    }
  }
`;

// Get Data Source By Id
export const dataSourceStandardsCount = /* GraphQL */ `
  query ($id: Int!) {
    dataSource(id: $id) {
      id
      name
      ruleInstancesCount
    }
  }
`;

// Get Data Source By Id
export const dataSourceFilters = /* GraphQL */ `
  query ($id: Int!) {
    dataSource(id: $id) {
      id
      name
      filterRuleInstance {
        standardId
        dataSourceId
        enabledState
        id
        priority
        standard {
          versions {
            createdOn
            id
          }
        }
        latestVersion {
          id
          standardVersion {
            id
            standard {
              id
              enabled
              latestVersion {
                id
              }
            }
          }
          standardVersionId
        }
        title
      }
    }
  }
`;

// Get Rule Instance History
export const ruleInstanceHistory = /* GraphQL */ `
  query ($id: Int!, $reportId: UUID!, $instanceVersionId: Long!) {
    dataSource(id: $id) {
      id
      reportById(reportId: $reportId) {
        ruleInstanceHistory(instanceVersionId: $instanceVersionId) {
          metrics {
            key
            value
          }
          ruleInstanceName
        }
      }
    }
  }
`;

// Get Rule Instance History Latest
export const ruleInstanceHistoryLatestReport = /* GraphQL */ `
  query ($id: Int!, $instanceVersionId: Long!) {
    dataSource(id: $id) {
      id
      latestReport {
        ruleInstanceHistory(instanceVersionId: $instanceVersionId) {
          metrics {
            key
            value
          }
          ruleInstanceName
        }
      }
    }
  }
`;

// Get All Data Sources
export const getAllDataSources = /* GraphQL */ `
  query {
    availableWorkflowDataSources {
      id
      name
      enabled
      ingressInfo {
        id
      }
      etlPipeline {
        dataQualityThreshold
      }
    }
  }
`;

export const availableCrosstableDataSources = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $order: [DataSourceSortInput!]
    $where: DataSourceFilterInput
  ) {
    availableCrosstableDataSources(
      first: $first
      after: $after
      order: $order
      where: $where
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          tagInstances {
            id
            remoteObjectId
            tag {
              name
              description
              id
            }
            tagId
            type
          }
          columns {
            dataSourceId
            dataType
            enabled
            fullyQualifiedName
            id
            name
            flags
            ordinal
          }
          enabled
          priorityLevel
          activeMonitoring
          nextScheduledReport
          reportStatus
          connection {
            id
            name
          }
          ingressInfo {
            id
          }
          ruleInstancesCount
          latestReport {
            timestamp
            refreshSummaryId
            refreshSummary {
              createdOn
              resultState
            }
            qualityScore {
              score
            }
          }
        }
      }
    }
  }
`;

export const allCrossTableDataSources = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $order: [DataSourceSortInput!]
    $where: DataSourceFilterInput
  ) {
    availableCrosstableDataSources(
      first: $first
      after: $after
      order: $order
      where: $where
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          columns {
            dataSourceId
            dataType
            enabled
            fullyQualifiedName
            id
            name
            flags
            ordinal
          }
          enabled
        }
      }
    }
  }
`;

// Get Data Source Tags
export const dataSourceTags = /* GraphQL */ `
  query ($id: Int!) {
    dataSource(id: $id) {
      id
      name
      enabled
      tagInstances {
        id
        remoteObjectId
        tag {
          name
          description
          id
        }
        tagId
        type
      }
    }
  }
`;

// Get Data Source Tags
export const resultFromSqlQuery = /* GraphQL */ `
  query ($id: Int!, $query: String!) {
    resultFromSqlQuery(id: $id, query: $query)
  }
`;

// Get Source By Id Widget Summary
export const sourceWidgetSummary = /* GraphQL */ `
  query ($id: Int!) {
    dataSource(id: $id) {
      name
      id
      latestReport {
        timestamp
        refreshSummaryId
        refreshSummary {
          createdOn
          resultState
        }
        qualityScore {
          score
          date
        }
        ${rowCount.fragment}
        ${totalFailures.fragment}
        ${failureOpportunities.fragment}
      }
    }
  }
`;

// Get Source By Id Widget Summary
export const sourceWidgetAPI = /* GraphQL */ `
  query ($id: Int!) {
    dataSource(id: $id) {
    # Unique identifier of the data source
    id
    # Name of the data source
    name
    # Description of the data source
    description
    # Date and time when the data source was created
    createdOn
    # Priority level of the data source
    priorityLevel
    # Quality impact score of the data source
    qualityImpact
    # Report status of the data source
    reportStatus
    # Count of rule instances associated with the data source
    ruleInstancesCount
    # Whether active monitoring is enabled for the data source
    activeMonitoring
    # Status indicating whether the data source is enabled
    enabled
    # Whether refresh is enabled for the data source
    refreshEnabled
    # Whether the primary key is configured for the data source
    primaryKeyConfigured
    # Whether the data source had a defined primary key previously
    hadDefinedPk
    # Date and time of the next scheduled report for the data source
    nextScheduledReport
    # Count of reports in the report history of the data source
    reportHistoryCount
    # Latest Source Report
    latestReport {
      # Identifier for the refresh summary
      refreshSummaryId
      # Timestamp of the latest report
      timestamp
      # The Report Summary Object Itself
      refreshSummary {
        # Date and time when the refresh summary was created
        createdOn
        # Batch name of the refresh summary
        batchName
      }
      # Profile Data for Columns
      columnProfiles {
        # Profile Results for Columns [array]
        profileResults {
          # Identifier of the column
          columnId
          # Value of the profile result
          value
          # Subtype of the value
          valueSubType
          # Type of the value
          valueType
        }
      }
      # Report Failure Details
      failureDetails {
        dataSourceMetrics {
          # Total count of failures
          totalFailuresCount
          # Total count of successful rows
          totalSuccessfulRowsCount
          # Total count of high-priority failures
          totalHighPriorityFailuresCount
          # Total count of other-priority failures
          totalOtherPriorityFailuresCount
        }
        # Columns Related to Failures
        columnEntries {
          # Identifier of the column
          columnId
          details {
            # Count of failures
            failureCount
            # Percentage of failures
            percentFailureCount
            # Name of the rule standard instance
            ruleStandardInstancedName
            # Standard column name
            standardColumnName
          }
          # Count of failures for the column
          failureCount
          # Change in failure count
          failureCountChange
          # Name of the column
          name
          # Previous top order
          previouslyTopOrder
        }
        # Policies Applied to the Source
        ruleStandardEntries {
          details {
            # Name of the column
            columnName
            # Count of failures
            failureCount
            # Percentage of failures
            percentFailureCount
            # Standard column name
            standardColumnName
          }
          # Count of failures for the rule standard
          failureCount
          # Change in failure count
          failureCountChange
          # Name of the rule standard
          name
          # Previous top order
          previouslyTopOrder
          # Identifier of the rule standard
          rulesStandardId
        }
      }
       ${failureOpportunities.fragment}
       ${totalFailures.fragment}
       ${rowCount.fragment}
       ${qualityScore.fragment}
       ${constraintFailuresCount.fragment}
       ${scoreHistory.fragment}
       ${ruleFailures.fragment}
       ${failuresHistory.fragment}
    }
    # Connection of the Source
    connection {
      id
      name
    }
    # Columns of the Source
    columns {
      # Unique identifier of the column
      id
      # Name of the column
      name
      # Ordinal position of the column
      ordinal
      # Status indicating whether the column is enabled
      enabled
      # Data type of the column
      dataType
      # User override data type of the column
      userOverrideDataType
      # Type of the column
      columnType
      # Flags associated with the column
      flags
      # Fully qualified name of the column including the source name
      fullyQualifiedName
      # A list of secondary columns matched to this column
      matchingColumn {
        # First matching data source column
        dataSourceColumn1
        # Second matching data source column
        dataSourceColumn2
        # Identifier of the first matching data source column
        dataSourceColumnId1
        # Identifier of the second matching data source column
        dataSourceColumnId2
        # Status indicating whether the matching column is enabled
        enabled

      }
    }
     # Alert Information for a Source
    alerts {
      # Unique identifier of the alert
      id
      # Type of the alert
      alertType
      # Status indicating whether the alert is enabled
      enabled
      # Type of the source for the alert
      sourceType
      # Identifier of the source for the alert
      sourceId
      # Trigger time of the alert
      triggerTime
      # Threshold for the alert
      threshold
      # Boolean flag for the alert
      boolFlag
    }
   }
  }
`;

// Get Source By Id Widget Summary
export const sourceReportQueriesScore = /* GraphQL */ `
  query ($id: Int!) {
    dataSource(id: $id) {
    # Unique identifier of the data source
    id
    # Name of the data source
    name
    # Latest Source Report
    latestReport {
       ${qualityScore.fragment}
    }
   }
  }
`;
