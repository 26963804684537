import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { MdArchive, MdHistory } from "react-icons/md";
import { Link } from "react-router-dom";
import StyledLink from "../../components/StyledLink";
import {
  dataSourceReport,
  manageDataSource,
  dataSourceReports,
} from "../../common/paths";
import Button from "../../components/Button";
import SortTable from "../../components/Table/SortTable";
import { format } from "date-fns";
import { useApi } from "../../api/useApi";
import {
  startDataSourceIngress,
  endRefreshSummary,
} from "../../api/dataSourceMutations";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import { setDataSourceEnabledFlag } from "../../api/dataSourceMutations";
import Modal from "../../components/Modal";
import Spinner from "../../components/Loaders/Spinner";
import TableButton from "../../components/Button/TableButton";
import useDataSourceNotifications from "../../Hooks/useDataSourceNotifications";
import { AuthContext } from "../../contexts/AuthContext";
import SearchDataSources from "../../components/Widgets/DataSourceWidgets/SearchDataSources";
import { sortByAlpha } from "../../common/helpers/util";
import { InLineTitlePin } from "../../components/Table/elements";
import TableActions from "../../components/Table/TableActions";
import PinButton from "../../components/Pin/PinButton";

const useIsRunning = (original) => {
  const [isRunning, setIsRunning] = useState(
    original?.reportStatus === "NONE" ||
      original?.reportStatus === "REFERENCE_REPORT_QUEUED"
      ? true
      : false
  );

  const { lastDataSourceNotification } = useDataSourceNotifications();

  useEffect(() => {
    const payload = lastDataSourceNotification?.payload;
    if (
      payload?.AlertType === 11 &&
      payload?.SourceId === original?.id &&
      payload?.Status === 2
    ) {
      setIsRunning(true);
    } else if (
      payload?.AlertType === 11 &&
      payload?.SourceId === original?.id &&
      payload?.Status === 1
    ) {
      setIsRunning(false);
    }
  }, [original, lastDataSourceNotification, setIsRunning]);

  return isRunning;
};

const ConnectionSourcesList = ({
  sources,
  getUpdatedConnection,
  connectionId,
}) => {
  const ref = useRef(null);

  //Set Function to enable child component to fetch parent reference for width
  const getTableWidth = () => {
    return ref?.current?.offsetWidth ?? 0;
  };

  const sortedSources = sortByAlpha(sources, "name");
  const [showConfirm, setShowConfirm] = useState(false);
  const [sourceToDelete, setSourceToDelete] = useState(null);

  const [{ loading: removalLoading, data: removalData }, remove] = useApi();

  const toggleSourceEnabled = ({ id, enabled }) => {
    remove({
      query: setDataSourceEnabledFlag,
      variables: { id: id, enabled: !enabled },
    });
  };

  const [{ errors }, startIngressProcess] = useApi();

  const [
    // {
    //   errors: cancelRefreshSummaryErrors,
    //   // loading: loadingCancelRefreshSummaryData,
    //   data: cancelRefreshSummaryData,
    // },
    cancelRefreshSummaryApi,
  ] = useApi();

  const { user } = useContext(AuthContext);

  const startIngress = useCallback(
    (id) => {
      startIngressProcess({
        query: startDataSourceIngress,
        variables: { dataSourceId: id },
      });
    },
    [startIngressProcess]
  );

  const cancelRefreshSummary = useCallback(
    (id) => {
      cancelRefreshSummaryApi({
        query: endRefreshSummary,
        variables: { dataSourceId: id },
      });
    },
    [cancelRefreshSummaryApi]
  );

  const columnsData = [
    {
      Header: "Name",
      id: "Name",
      accessor: "name",
      Cell: ({ row: { original } }) => {
        const modifiedName =
          original?.schema === ""
            ? original?.name
            : `${original?.schema}.${original?.name}`;
        return (
          <InLineTitlePin>
            {original?.ruleInstancesCount &&
            original?.latestReport?.refreshSummaryId ? (
              <StyledLink
                to={dataSourceReport(
                  original.id,
                  original?.latestReport?.refreshSummaryId
                )}
              >
                {modifiedName}
              </StyledLink>
            ) : (
              modifiedName
            )}
          </InLineTitlePin>
        );
      },
    },
    {
      Header: "Score",
      id: "score",
      width: 55,
      accessor: (d) => d?.latestReport?.qualityScore?.score,
      Cell: ({ row: { original } }) => {
        if (original.latestReport) {
          return original?.ruleInstancesCount
            ? original?.latestReport?.qualityScore?.score ?? 0
            : "N/A";
        } else {
          return "N/A";
        }
      },
    },
    {
      Header: "Updated",
      id: "updated",
      accessor: (d) => d?.latestReport?.refreshSummary?.createdOn,
      Cell: ({ row: { original } }) => {
        const date = original?.latestReport?.refreshSummary?.createdOn;
        if (date) {
          return format(new Date(date), "MM-dd-yyyy HH:mm:ss");
        } else {
          return "";
        }
      },
    },
    {
      Header: "Tags",
      id: "tags",
      Cell: ({ row: { original } }) => {
        return (
          <div>
            {original?.tagInstances?.map((ti, i) => {
              const isLast = original?.tagInstances?.length - 1 <= i;
              return (
                <div
                  style={{
                    display: "inline-block",
                    background: "#e6e6e6",
                    padding: "0.2rem",
                    paddingLeft: ".4rem",
                    paddingRight: ".4rem",
                    fontSize: ".8rem",
                    marginRight: isLast ? "" : ".5rem",
                    marginBottom: isLast ? "" : ".5rem",
                  }}
                >
                  {ti?.tag?.name}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      Header: " ",
      id: "actions",
      width: 250,
      resizable: false,
      sortable: false,
      Cell: ({ totalColumnsWidth, row: { original } }) => {
        const enabled = original?.enabled;
        const isRunning = useIsRunning(original);
        const resultState = original?.reportStatus;
        const ruleInstancesCount = original?.ruleInstancesCount;
        const hasReport = original?.latestReport?.refreshSummaryId;

        const isDisabled =
          isRunning ||
          (resultState === "NONE" && ruleInstancesCount) ||
          !ruleInstancesCount;

        return (
          <div style={{ textAlign: "right" }}>
            <TableActions
              minWidth={340}
              totalColumnsWidth={totalColumnsWidth}
              getTableWidth={getTableWidth}
            >
              {enabled && (
                <Link to={manageDataSource(original.id)}>
                  <TableButton list="true" type="button">
                    Manage
                  </TableButton>
                </Link>
              )}
              {enabled && (
                <Link
                  style={{ pointerEvents: !hasReport ? "none" : "inherit" }}
                  to={dataSourceReports(original.id)}
                >
                  <TableButton
                    title={"Report History"}
                    iconBump={false}
                    disabled={!hasReport}
                    type="button"
                    list="true"
                    bumpdown={true}
                  >
                    <MdHistory />
                  </TableButton>
                </Link>
              )}

              {isRunning && user && user.role >= 1 ? (
                <TableButton
                  list="true"
                  type="button"
                  danger={true}
                  onClick={() => cancelRefreshSummary(original?.id)}
                >
                  Cancel Report
                </TableButton>
              ) : enabled && user && user.role >= 1 ? (
                <TableButton
                  list="true"
                  type="button"
                  onClick={() => {
                    if (!isDisabled) startIngress(original?.id);
                  }}
                  disabled={isDisabled}
                >
                  Run Report
                </TableButton>
              ) : null}
              {user && user.role >= 2 && (
                <TableButton
                  danger={enabled}
                  list="true"
                  type="button"
                  id={`${original?.name}-toggleConnectionSourceArchiveReactivate`}
                  data-testid={`${original?.name}-toggleConnectionSourceArchiveReactivate`}
                  bumpdown={true}
                  onClick={() =>
                    setSourceToDelete({ id: original.id, enabled: enabled })
                  }
                >
                  {enabled ? <MdArchive /> : "Reactivate"}
                </TableButton>
              )}
              <PinButton type="sources" item={original?.id} />
            </TableActions>
          </div>
        );
      },
    },
  ];

  //handle remove rule update
  useEffect(() => {
    if (removalData) {
      getUpdatedConnection();
    }
  }, [removalData, getUpdatedConnection]);

  useEffect(() => {
    if (sourceToDelete) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [sourceToDelete]);

  return (
    <>
      {showConfirm ? (
        <Modal
          title={`Confirm Source ${
            sourceToDelete?.enabled ? "Archival" : "Reactivation"
          }`}
          hide={() => setSourceToDelete(null)}
        >
          <p>
            Are you sure you wish to{" "}
            {sourceToDelete?.enabled ? "archive" : "reactivate"} this source?
          </p>
          <div>
            <Button
              type="button"
              list="true"
              disabled={removalLoading}
              danger
              onClick={() => toggleSourceEnabled(sourceToDelete)}
            >
              {removalLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              type="button"
              disabled={removalLoading}
              onClick={() => {
                setSourceToDelete(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      ) : null}

      {errors && <ErrorMessages errors={errors} />}

      <div style={{ marginTop: "1rem" }}>
        <SearchDataSources connectionId={connectionId} />
      </div>
      <div ref={ref}>
        <SortTable data={sortedSources ?? []} columns={columnsData} />
      </div>
    </>
  );
};

export default ConnectionSourcesList;
